






































































































































































































































































































































































































































































































































































































































































.menuItemIcon {
  width: 21px;
  padding-right: 5px;
}
.header {
  width: 103%;
  height: 50px;
  background: #fff;
  margin: -20px 0 0 -32px;
}

.logo {
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;

  background: #1e7cee;
  color: #ffffff;
  z-index: 1;
  font-weight: 700;
  img {
    margin-left: 10px;
  }
  span {
    font-size: 25px;
    margin: -2px 0 0 6px;
  }
}
#copwier {
  width: 40px;
  height: 40px;
  margin: 20px 0 0 10px;
}
#copwie {
  width: 30px;
  height: 30px;
  margin: 10px 0 0 70px;
}
.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
}
.el-submenu__title > i {
  font-size: 21px;
  color: rgb(177, 212, 255);
}
.home-container {
  height: 100%;
  .el-header {
    background: #ffffff;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div:nth-child(1) {
      display: flex;
      align-items: center;
      width: 300px;
      background: #fff;
      margin-left: -20px;
      height: 100%;
      .headeropc {
        display: flex;
        align-items: center;
        width: 200px;
        height: 100%;

        background: #1e7cee;
      }
      .headeropcr {
        display: flex;
        align-items: center;
        width: 64px;
        height: 100%;

        background: #1e7cee;
      }
      img {
        width: 40px;
        margin-left: 0px;
      }

      span {
        margin-left: 0px;
        font-size: 30px;
        font-weight: 700;
      }
    }
  }
  span {
  }
  .el-aside {
    background: #1e7cee;

    .el-menu {
      border-right: 0;
      overflow: hidden;
    }
  }
}
