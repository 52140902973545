




























































































































































































.pull-rightc {
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
  color: #005980;
  float: left;
  margin: -10px 0 0 100px;
}
input {
  color: #666;
}
.span {
  text-decoration: none;
  cursor: pointer;
  color: #005980;
  font-size: 12px;
  margin: -10px 0px 10px 0;
  float: right;
}
.loginbox ::v-deep .el-input__inner {
  background-color: #fff !important;
}
.login_container {
  background: url("http://oss.fuhangbeiyue.com/old_images/fhjt/image/1605092283168");
  background-position: 50%;
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;
  .loginbox {
    width: 380px;
    height: 260px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    right: 120px;
    transform: translate(0, -50%);

    .login_form {
      position: absolute;
      top: 55%;
      left: 40%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      -webkit-user-select: none;
      user-select: none;
      .btns {
        .loginBtn {
          width: 100%;
          margin: 10px 0 0 0px;
        }
      }
    }
  }
}
