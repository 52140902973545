/* 全局样式表 */
html,
body,
#app {
    height: 100%;
    margin: 0;
    padding: 0;
    min-width: 1080px;
    font-family: 'PangMenZhengDao' !important;
    /* filter: grayscale(100%); */
        /* -webkit-filter: grayscale(100%); */
}
.el-popper{
    max-width: 800px;
  }
.el-menu-vertical-demo:not(.el-menu--collapse){
		width: 250px;			
		height: 100%;
	}
.el-breadcrumb {
    margin-bottom: 15px;
    font-size: 12px;
}

.el-menu-item-group__title{
    padding: 0;
    display: none;
}
.el-upload-list--picture-card .el-upload-list__item{
    margin: 0 8px 0px 0;
}
.el-menu-item:hover{
    background: rgb(24, 99, 190) !important;
    color: #fff!important;
  }
  .el-submenu__title:hover {
    background: rgb(24, 99, 190) !important;
    color: #fff!important;
    
  }
  .el-submenu__title:hover {
    background: rgb(24, 99, 190) !important;
    color: #fff!important;
  }
  .el-menu-item.is-active {
    background: rgb(24, 99, 190) !important;
    color: #fff!important;
  }
  .el-submenu__title.is-active {
    background: rgb(24, 99, 190) !important;
    color: #fff!important;
  }

.el-card {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15) !important;
}

.el-table {
    margin-top: 15px;
    font-size: 12px;
}

.el-pagination {
    margin-top: 15px;
}

main {
    background: #e7e7ee;
    height: calc(100vh - 60px);
}

aside {
    height: calc(100vh - 60px ) !important ;
    overflow-x: hidden;

    overflow-y: scroll;
}
aside::-webkit-scrollbar {

    display: none;
  
  }
.el-cascader-panel {
    height: 200px;
}
.el-menu-item-group{
    background: #fff;
}
.zk-table__header-row {
    color: #909399;
}

.zk-table--firstProp-header-inner {
    padding-left: 12px !important;
}
.el-table .el-table__cell{
    padding:8px  0 ;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

p {
    margin: 0;
    padding: 0;
}

.head{
   
    width: 104%;
    height: 62px;
    background:#fff;
   
    border-bottom: 1.5px solid rgb(230, 228, 228);
  
}
.el-icon{
    width: 30px !important;
}
.tui{
  width: 20px;
  height: 20px;
  float: right;
  margin: 25px  ;
  padding-right: 100px;
}
.el-menu-item.is-active {
    color: #fff !important;
    background: rgb(24, 99, 190) !important;
  }
  